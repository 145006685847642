<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="*Code"
          name="Code"
          v-model="data.code"
          readonly
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Code')">{{
          errors.first("Code")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">*Group</label>
          <div class="vx-row">
              <multiselect
                v-model="data.reasonGroup.selected"
                :options="data.reasonGroup.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
                @input="onChangeReason"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
          </div>

          <span
            class="text-danger text-sm"
            v-show="errors.has('ReasonGroup')"
            >{{ errors.first("ReasonGroup") }}</span
          >
        </div>
      </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="*Reason"
          v-validate="'required'"
          name="Reason"
          v-model="data.reason"
        />
        <span class="text-danger text-sm" v-show="errors.has('Reason')">{{
          errors.first("Reason")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6" v-if="data.reasonType.options.length > 0">
      <div class="vx-col w-full">
        <label class="vs-input--label">*Type</label>
        <div class="vx-row">
            <multiselect
              v-model="data.reasonType.selected"
              :options="data.reasonType.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>

            <span
              class="text-danger text-sm"
              v-show="errors.has('ReasonType')"
              >{{ errors.first("ReasonType") }}</span
            >
        </div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Status</label>
        <vs-switch
          label="*Status"
          name="is_active"
          v-model="data.is_active"
          color="success"
          :disabled="false"
          :value="true"
          :true-value="true"
          :false-value="false"
        >
        </vs-switch>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      type: Number,
    },
    isDefault: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          code: null,
          reason: "",
          is_active: true,
          reasonGroup: {
            selected: null,
            options: [],
          },
          reasonType: {
            selected: null,
            options: [],
          },
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.data.reasonGroup.selected) {
          this.errors.add({
            field: "ReasonGroup",
            msg: "Group field is required",
          });
          result = false;
        }
        if (this.data.reasonType.options.length > 0) {
          if (!this.data.reasonType.selected) {
            this.errors.add({
              field: "ReasonType",
              msg: "Type field is required",
            });
            result = false;
          }
        }
        if (this.data.code == null){
          this.errors.add({
              field: "Code",
              msg: "Please setting master number",
            });
            result = false;
        }
        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        code: this.data.code,
        reason: this.data.reason,
        is_active: this.data.is_active,
        reason_group: this.data.reasonGroup.selected.name,
        type: this.data.reasonType.selected ? this.data.reasonType.selected.name : " ",
        reason_group_id: this.data.reasonGroup.selected.ID,
        reason_type_id: this.data.reasonType.selected ? this.data.reasonType.selected.ID : null,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.ID = resp.data.ID;
            this.data.code = resp.data.code;
            this.data.is_active = resp.data.is_active;
            this.data.reason = resp.data.reason;
            if (resp.data.reason_group != "") {
              this.setReasonGroupSelected(resp.data.reason_group_id);
            }
            if (resp.data.type != "") {
              this.getReasonType(resp.data.reason_group)
              this.setReasonTypeSelected(resp.data.reason_type_id);
            }else{
              this.data.reasonType.options = []
              this.data.reasonType.selected.name = ""
            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getCode() {
      this.$vs.loading();
      this.$http
        .get(this.url + "-code", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.data.code = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getReasonGroup() {
      this.$vs.loading();
      this.$http
        .get(this.url + "-group", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.data.reasonGroup.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getReasonType(group) {
      this.$vs.loading();
      this.$http
        .get(this.url + "-type", {
          params: {
            order: "name",
            sort: "asc",
            reason_group: group,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.data.reasonType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setReasonGroupSelected(id) {
      this.$vs.loading();
      this.$http.get(this.url + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.data.reasonGroup.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setReasonTypeSelected(id) {
      this.$vs.loading();
      this.$http.get(this.url + "-type/" + id).then((resp) => {
        if (resp.status == "success") {
          this.data.reasonType.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    onChangeReason(value){
      console.log("onChangeReason", value);
      this.data.reasonType.selected = null
      this.getReasonType(value.name)
    },
  },
  mounted() {
    this.getReasonGroup();
    if (this.id) {
      this.getData();
    }else if(this.data.code == null){
      console.log("mounted getCode()", this.data.code)
      this.getCode();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getReasonGroup();
        this.getData();
        if(this.data.code == null){
          console.log("watch getCode", this.data.code)
          this.getCode();
        }
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style >
</style>
